<template>
  <v-container
    class="rounded elevation-3 bg-white h-full"
    fluid>
    <v-row>
      <v-col cols="12">
        <span class="header pl-2">Supplier Apply</span>
      </v-col>
    </v-row>
    <v-row
      class="mt-5"
      no-gutters>
      <v-col cols="12">
        <v-row
          justify="end"
          class="head-search ma-0">
          <v-col
            cols="10"
            class="d-flex justify-end align-center">
            <v-select
              v-model="selectServiceType"
              label="Service"
              :items="serviceTypes"
              item-text="label"
              item-value="value"
              hide-details
              dense
              outlined
              @change="fetchReports(true)" />
            <v-select
              v-model="selectState"
              label="State"
              class="ml-2"
              :items="Object.keys(states)"
              hide-details
              dense
              outlined
              @change="fetchReports(true)" />
            <search-box
              v-model="searchInput"
              class="ml-2"
              @on-search="fetchReports(true)" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          dense
          :headers="headers"
          :items-per-page="-1"
          :items="reports"
          hide-default-footer
          no-data-text="ไม่มีข้อมูล"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          loading-text="กรุณารอสักครู่..."
          :expanded.sync="itemExpanded"
          show-expand
          @update:sort-by="fetchReports"
          @update:sort-desc="fetchReports">
          <template
            v-slot:expanded-item="{ headers: head, item }"
          >
            <td></td>
            <td
              class="pa-4"
              :colspan="head.length -1">
              <div v-if="item.serviceTypeDetail && item.serviceTypeDetail !== ''">
                <h3>รายละเอียด</h3>
                <p>{{ item.serviceTypeDetail }}</p>
              </div>
              <div>
                <h3>จำนวนช่าง</h3>
                <p>
                  จำนวนช่าง : {{ item.numberOfSeamstress }}<br>
                  จำนวนช่างขึ้นแบบ : {{ item.numberOfModeler }}
                </p>
              </div>
              <div>
                <h3>Company</h3>
                <p>
                  {{ item.companyName }} ({{ item.companyAddress }}) <br>
                  Email : {{ item.email }} <br>
                  Web site : {{ item.website }} <br>
                  Social : {{ item.social }} <br>
                  ปริมาณผลิตในแต่ละเดือน : {{ item.monthlyProductionCap }}
                </p>
              </div>
              <div>
                <h3>ผลงาน</h3>
                <p>{{ item.works }}</p>
              </div>
              <h3>รูป</h3>
              <div class="d-flex flex-wrap">
                <div
                  v-for="(image, ind) of item.images"
                  :key="image"
                  class="image-preview-wrapper"
                  @click="selectImagePreview(item.images, ind)">
                  <img
                    class="image-preview"
                    :src="image"
                    alt="">
                </div>
              </div>
            </td>
          </template>
          <template #[`item.createdAt`]="{ item }">
            <span>
              {{ getDateFormat(item.createdAt) }}
            </span>
          </template>
          <template #[`item.social`]="{ item }">
            <span
              v-if="!!item.social && isUrl(item.social)"
              class="open-link"
              @click="downloadFile(item.social)">
              Open
            </span>
            <span v-else-if="!!item.social">
              {{ item.social }}
            </span>
            <span v-else>
              -
            </span>
          </template>
          <template #[`item.state`]="{ item }">
            <v-chip
              :color="states[item.state].color"
              small
              dark>
              {{ item.state }}
            </v-chip>
          </template>
          <template #[`item.option`]="{ item }">
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(state, index) of Object.keys(states).filter(s => s !== 'all')"
                  :key="index + '-state'"
                  @click="updateState(item, state)">
                  {{ state }}
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination
          v-model="page"
          :length="totalPages"
          total-visible="7"
        />
      </v-col>
    </v-row>
    <MultipleImagePreview
      v-if="modalPreview"
      v-model="selectPreviewIndex"
      :images="selectPreviewImage"
      @onClose="modalPreview = false " />
  </v-container>
</template>

<script>
import SearchBox from '@/components/SearchBox.vue'
import MultipleImagePreview from '@/components/MultipleImagePreview.vue'
import { mapActions } from 'vuex'
import SupplierProvider from '@/resources/SupplierProvider'

const SupplierService = new SupplierProvider()
export default {
  components: { SearchBox, MultipleImagePreview },
  data () {
    return {
      states: {
        'all': {
          color: 'black'
        },
        'pending': {
          color: 'amber'
        },
        'waiting': {
          color: 'light-blue'
        },
        'Not answer': {
          color: 'pink'
        },
        'qualified': {
          color: 'green'
        },
        'not suitable': {
          color: 'grey'
        },
        'completed': {
          color: 'cyan darken-4'
        }
      },
      selectState: 'all',
      selectServiceType: 'all',
      page: 1,
      perPage: 20,
      totalPages: 0,
      totalReports: 0,
      sortBy: 'createdAt',
      sortDesc: true,
      modalPreview: false,
      selectPreviewImage: [],
      selectPreviewIndex: 0,
      serviceTypes: [],
      itemExpanded: [],
      headers: [
        {
          text: 'Date',
          value: 'createdAt',
          sortable: true,
          align: 'center',
          width: '150px'
        },
        {
          text: 'Contact Name',
          value: 'contactName',
          sortable: false,
          align: 'start'
        },
        {
          text: 'Company Name',
          value: 'companyName',
          sortable: false,
          align: 'start'
        },
        {
          text: 'Contact No.',
          value: 'contactNo',
          sortable: false,
          align: 'center'
        },
        {
          text: 'Service Type',
          value: 'serviceType',
          sortable: false,
          align: 'center'
        },
        {
          text: 'State',
          value: 'state',
          align: 'center'
        },
        {
          text: 'Option',
          value: 'option',
          sortable: false,
          align: 'center'
        }
      ],
      reports: [],
      searchInput: ''
    }
  },
  watch: {
    page () {
      this.fetchReports()
    }
  },
  mounted () {
    this.fetchReports()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading'
    }),
    selectImagePreview (images, index = 0) {
      this.selectPreviewImage = images
      this.selectPreviewIndex = index
      this.modalPreview = true
    },
    async updateState (item, state) {
      try {
        this.setLoading({ active: true })
        const { data } = await SupplierService.updateState(item.id, {
          state
        })
        if (data) this.fetchReports()
      } catch (error) {
        console.error('fetchReports', error)
        this.setSnackbar({
          value: true,
          message: `[GET-REPORT-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async fetchReports (isClear = false) {
      try {
        this.setLoading({ active: true })

        if (isClear) {
          this.page = 1
        }

        const query = {
          page: this.page,
          limit: this.perPage,
          sortBy: this.sortBy,
          state: this.selectState,
          sortOrder: this.sortDesc ? 'desc' : 'asc',
          serviceType: this.selectServiceType,
          search: this.searchInput
        }

        const { data: count } = await SupplierService.count(query)

        this.serviceTypes = count.map((c) => ({
          value: c.id,
          label: `${c.id} (${c.count})`
        }))

        const { data } = await SupplierService.getCareers(query)

        this.totalPages = data.pages
        this.totalReports = data.total
        this.reports = data.results
        this.itemExpanded = data.results
      } catch (error) {
        console.error('fetchReports', error)
        this.setSnackbar({
          value: true,
          message: `[GET-REPORT-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    getDateFormat (date) {
      return this.$dayjs(date).format('DD/MM/YYYY HH:mm')
    },
    isUrl (url) {
      return /^[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/ig.test(url)
    },
    downloadFile (url) {
      window.open(url)
    }
  }
}
</script>

<style scoped>
.bg-white {
  background-color: #fff;
}
.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}
.header {
  font-size: 1.5rem;
  font-weight: bold;
}
.image-preview-wrapper {
  width: 200px;
  height: 200px;
  padding: 3px;
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #000;
  background-color: #fff;
}
.image-preview {
  max-width: 100%;
  max-height: 100%;
}
</style>
