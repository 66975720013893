var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"rounded elevation-3 bg-white h-full",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"header pl-2"},[_vm._v("Supplier Apply")])])],1),_c('v-row',{staticClass:"mt-5",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"head-search ma-0",attrs:{"justify":"end"}},[_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"cols":"10"}},[_c('v-select',{attrs:{"label":"Service","items":_vm.serviceTypes,"item-text":"label","item-value":"value","hide-details":"","dense":"","outlined":""},on:{"change":function($event){return _vm.fetchReports(true)}},model:{value:(_vm.selectServiceType),callback:function ($$v) {_vm.selectServiceType=$$v},expression:"selectServiceType"}}),_c('v-select',{staticClass:"ml-2",attrs:{"label":"State","items":Object.keys(_vm.states),"hide-details":"","dense":"","outlined":""},on:{"change":function($event){return _vm.fetchReports(true)}},model:{value:(_vm.selectState),callback:function ($$v) {_vm.selectState=$$v},expression:"selectState"}}),_c('search-box',{staticClass:"ml-2",on:{"on-search":function($event){return _vm.fetchReports(true)}},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items-per-page":-1,"items":_vm.reports,"hide-default-footer":"","no-data-text":"ไม่มีข้อมูล","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"loading-text":"กรุณารอสักครู่...","expanded":_vm.itemExpanded,"show-expand":""},on:{"update:expanded":function($event){_vm.itemExpanded=$event},"update:sort-by":_vm.fetchReports,"update:sort-desc":_vm.fetchReports},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var head = ref.headers;
var item = ref.item;
return [_c('td'),_c('td',{staticClass:"pa-4",attrs:{"colspan":head.length -1}},[(item.serviceTypeDetail && item.serviceTypeDetail !== '')?_c('div',[_c('h3',[_vm._v("รายละเอียด")]),_c('p',[_vm._v(_vm._s(item.serviceTypeDetail))])]):_vm._e(),_c('div',[_c('h3',[_vm._v("จำนวนช่าง")]),_c('p',[_vm._v(" จำนวนช่าง : "+_vm._s(item.numberOfSeamstress)),_c('br'),_vm._v(" จำนวนช่างขึ้นแบบ : "+_vm._s(item.numberOfModeler)+" ")])]),_c('div',[_c('h3',[_vm._v("Company")]),_c('p',[_vm._v(" "+_vm._s(item.companyName)+" ("+_vm._s(item.companyAddress)+") "),_c('br'),_vm._v(" Email : "+_vm._s(item.email)+" "),_c('br'),_vm._v(" Web site : "+_vm._s(item.website)+" "),_c('br'),_vm._v(" Social : "+_vm._s(item.social)+" "),_c('br'),_vm._v(" ปริมาณผลิตในแต่ละเดือน : "+_vm._s(item.monthlyProductionCap)+" ")])]),_c('div',[_c('h3',[_vm._v("ผลงาน")]),_c('p',[_vm._v(_vm._s(item.works))])]),_c('h3',[_vm._v("รูป")]),_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((item.images),function(image,ind){return _c('div',{key:image,staticClass:"image-preview-wrapper",on:{"click":function($event){return _vm.selectImagePreview(item.images, ind)}}},[_c('img',{staticClass:"image-preview",attrs:{"src":image,"alt":""}})])}),0)])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getDateFormat(item.createdAt))+" ")])]}},{key:"item.social",fn:function(ref){
var item = ref.item;
return [(!!item.social && _vm.isUrl(item.social))?_c('span',{staticClass:"open-link",on:{"click":function($event){return _vm.downloadFile(item.social)}}},[_vm._v(" Open ")]):(!!item.social)?_c('span',[_vm._v(" "+_vm._s(item.social)+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.states[item.state].color,"small":"","dark":""}},[_vm._v(" "+_vm._s(item.state)+" ")])]}},{key:"item.option",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',_vm._l((Object.keys(_vm.states).filter(function (s) { return s !== 'all'; })),function(state,index){return _c('v-list-item',{key:index + '-state',on:{"click":function($event){return _vm.updateState(item, state)}}},[_vm._v(" "+_vm._s(state)+" ")])}),1)],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-pagination',{attrs:{"length":_vm.totalPages,"total-visible":"7"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1),(_vm.modalPreview)?_c('MultipleImagePreview',{attrs:{"images":_vm.selectPreviewImage},on:{"onClose":function($event){_vm.modalPreview = false}},model:{value:(_vm.selectPreviewIndex),callback:function ($$v) {_vm.selectPreviewIndex=$$v},expression:"selectPreviewIndex"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }