<template>
  <div
    class="preview-wrap">
    <v-btn
      class="preview-image-close"
      elevation="5"
      icon
      @click="closeDialog()">
      <v-icon color="black">
        mdi-close
      </v-icon>
    </v-btn>
    <div>
      <img
        :src="images[showingIndex]"
        class="preview-image"
        alt="preview-image">
      <div class="preview-paginate">
        <div
          v-for="ind of images.length"
          :key="ind"
          class="cs-pointer mx-2 preview-paginate-item"
          :class="{ 'preview-paginate-item-select': ind === showingIndex + 1 }"
          @click="showingIndex = ind - 1">
          {{ ` ${ind} ` }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      required: true
    },
    value: {
      type: Number,
      default: 0
    }
  },
  computed: {
    showingIndex: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  },
  methods: {
    closeDialog () {
      this.$emit('onClose')
    }
  }
}

</script>

<style scoped>
.preview-image-close {
  position: absolute;
  background-color: white;
  top: 10px;
  right: 20px;
}
.preview-image {
  max-width: 80vw;
  max-height: 80vh;
}
.preview-paginate-item {
  border: 1px solid #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .7s;
}

.preview-paginate-item-select {
  background-color: #fff;
  color: #000;
}

.preview-paginate {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  margin-top: 10px;
  font-size: 20px;
  position: fixed;
  left: 0;
  bottom: calc(5vh - 10px);
  width: 100vw;
}
.preview-wrap {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: #0000007e;
  z-index: 99999999;
}
</style>
