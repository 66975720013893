import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class SupplierProvider extends HttpRequest {
  getCareers (query) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/supplier-applies', query)
  }

  count (query) {
    this.setHeader(getAuthToken())
    return this.get('/supplier-applies/count', query)
  }

  createCareer (payload) {
    this.setHeader(getAuthToken())
    return this.post('/supplier-applies', payload)
  }

  editCareer (id, payload) {
    this.setHeader(getAuthToken())
    return this.put(`/supplier-applies/${id}`, payload)
  }

  updateState (id, payload) {
    this.setHeader(getAuthToken())
    return this.patch(`/supplier-applies/${id}/state`, payload)
  }

  deleteCareer (params) {
    this.setHeader(getAuthToken())
    return this.delete(`/supplier-applies/${params}`)
  }
}

export default SupplierProvider
